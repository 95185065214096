@font-face {
    font-family: 'Marianne';
    src:
        url('./fonts/marianne/Marianne-Thin.woff2') format('woff2'),
        url('./fonts/marianne/Marianne-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Marianne';
    src:
        url('./fonts/marianne/Marianne-Regular.woff2') format('woff2'),
        url('./fonts/marianne/Marianne-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Marianne';
    src:
        url('./fonts/marianne/Marianne-Medium.woff2') format('woff2'),
        url('./fonts/marianne/Marianne-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Marianne';
    src:
        url('./fonts/marianne/Marianne-Bold.woff2') format('woff2'),
        url('./fonts/marianne/Marianne-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}


/* @font-face {
    font-family: 'Marianne';
    src:
        url('./fonts/marianne/marianne-regular-webfont.woff2') format('woff2'),
        url('./fonts/marianne/marianne-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Marianne';
    src:
        url('./fonts/marianne/marianne-bold-webfont.woff2') format('woff2'),
        url('./fonts/marianne/marianne-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
} */

@font-face {
    font-family: 'Archia';
    src:
        url('./fonts/archia/archia-regular-webfont.woff2') format('woff2'),
        url('./fonts/archia/archia-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Archia';
    src:
        url('./fonts/archia/archia-semibold-webfont.woff2') format('woff2'),
        url('./fonts/archia/archia-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Archia';
    src:
        url('./fonts/archia/archia-bold-webfont.woff2') format('woff2'),
        url('./fonts/archia/archia-bold-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
